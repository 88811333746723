import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Lawsuits(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="md:w-10/12">
                <h2>
                    Understanding the Zantac Lawsuit: Key Developments and
                    Allegations?
                </h2>
                <p>
                    As of April 2020, the U.S. Food and Drug Administration
                    (FDA) mandated the immediate withdrawal of all Zantac brand
                    heartburn medications, both prescription and
                    non-prescription, due to concerns over NDMA contamination.
                    NDMA is a substance recognized for its potential
                    carcinogenic effects. The inaugural lawsuit against Zantac
                    emerged in California in 2019. Within this legal action,
                    plaintiffs level allegations against pharmaceutical
                    companies Sanofi and Boehringer Ingelheim, claiming these
                    entities produced, marketed, and distributed a product
                    allegedly tainted with an industrial chemical known to be
                    carcinogenic, despite being aware or reasonably expected to
                    be aware of such contamination.
                </p>
            </div>
            <div className="relative md:ml-10 w-[248px] h-[216px] mb-10 md:mb-0">
                <Image
                    src="/bestzantaclawyers/assets/images/zantacOpt.png"
                    alt="zantac"
                    layout="fill"
                />
            </div>
        </div>
    );
}
